import HomeIntro from './home-intro';
import HomeFeatures from './home-features';

import { FaCodeBranch } from 'react-icons/fa';
import { Center, Text } from '@chakra-ui/react';

const Home = () => {
  const version = process.env.REACT_APP_VERSION ?? 'Unknown';

  return (
    <>
      <HomeIntro />

      <HomeFeatures />

      <Center mb={5}>
        <FaCodeBranch />
        <Text>
          Version: {version}
        </Text>
      </Center>
    </>
  );
};

export default Home;
