import './App.css';
import './css/tajweed.css';
import './css/arabic.css';

import { ChakraProvider, ThemeConfig, extendTheme } from '@chakra-ui/react';

import AppRoutes from './util/routes';

const config: ThemeConfig = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const theme = extendTheme({
	config,
	fonts: {
		heading: 'Open Sans, sans-serif',
		body: 'Raleway, sans-serif',
	},
});

function App() {
	return (
		<ChakraProvider theme={theme}>
			<div className="App">
				<AppRoutes />
			</div>
		</ChakraProvider>
	);
}

export default App;
